<template>
  <v-main
    :style="
      this.$vuetify.theme.dark ? 'background: #272727;' : 'background: #f5f5f5;'
    "
  >
    <router-view />
    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    // DashboardCoreFooter: () => import("./Footer"),
  },
};
</script>
