import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import {store} from "./store/store";
import i18n from "./i18n";
import "./plugins/base";
import Vue2Filters from "vue2-filters";
import axios from "axios";
import "@mdi/font/css/materialdesignicons.css";
import DatetimePicker from "vuetify-datetime-picker";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSplash from "vue-splash";
// import jwt_decode from "jwt-decode";
import VueExcelXlsx from "vue-excel-xlsx";


import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueSplash);

const moment = require("moment");
moment.locale(localStorage.getItem("lang") == "en" ? "en" : "ar-dz");
Vue.use(require("vue-moment"), {moment});

Vue.use(Toast, {
  position: localStorage.rtl === "true" ? "bottom-left" : "bottom-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  closeButton: "button",
  icon: true,
  rtl: localStorage.rtl === "true",
});

Vue.use(DatetimePicker);
Vue.use(Vue2Filters);
Vue.use(VueExcelXlsx);

Vue.config.devtools = true;

Vue.config.productionTip = false;

axios.defaults.withCredentials = false;


if (axios.defaults.baseURL === "" || axios.defaults.baseURL === undefined) {
  axios.get("static/config.json").then((res) => {
    axios.defaults.baseURL = res.data.VUE_APP_URL;
  });
}
 // axios.defaults.baseURL= "http://localhost:8000/api"
axios.defaults.baseURL= "https://platform.school.iq/api/"
axios.defaults.headers.get.Accepts = "application/json";
axios.interceptors.request.use(
    (config) => {
      // store.commit("overlay", true);

      const token = localStorage.getItem("token");
      const auth = token ? `Bearer ${token}` : "";
      config.headers.common.Authorization = auth;
      return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    function (response) {
      if (!response.config.noToast && response.config.method !== 'get') {
        if (response.data.status === "Successful" || response.data.status === "Ok") {
          Vue.$toast.success(i18n.t("operationAccomplishedSuccessfully"));
        } else {
          Vue.$toast.error(i18n.t("error." + response.data.message));
        }
      }
      return response;
    },
    function (error) {

      store.commit("overlay", false);
      if (error.response && error.response.status === 401) {
        router.push({name: "Login"});
      } else {
        if (error.toJSON().config.failureToast) {
          if (error.response.data.message)
            Vue.$toast.error(error.response.data.message);
          else
            Vue.$toast.error(i18n.t("AnErrorOccurredDuringTheProcess"));
        }
      }
      return Promise.reject(error);
    }
);

Vue.prototype.$axios = axios;

// Require Froala Editor js file.
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/plugins/colors.min.js'
// import './plugins/froala_editor/js/plugins/font_family.min.js'
// import './plugins/froala_editor/js/plugins/font_size.min.js'
import './plugins/froala_paragraph_format_extended.min.js'
import './plugins/froala_ar.js'

// Require Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css')
require('froala-editor/css/froala_style.min.css')

// Import and use Vue Froala lib.
import VueFroala from 'vue-froala-wysiwyg'

Vue.use(VueFroala)

Vue.filter('format_date', value => new Date(value).toLocaleDateString('ar-EG-u-nu-latn', {
  year: 'numeric', month: 'numeric', day: 'numeric'
}));
Vue.filter('format_date_time', value => new Date(value).toLocaleDateString('ar-EG-u-nu-latn', {
  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
}));
Vue.filter('format_date_time_en', value => new Date(value).toLocaleDateString('en-US-u-nu-latn', {
  year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',
}));
new Vue({
  store: store,
  router,
  i18n,
  axios,
  vuetify,
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
  // directives: {
  //   print,
  // },
  render: (h) => h(App),
}).$mount("#app");
