/* import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#009688",
      },
      dark: {
        primary: "#009688",
      },
    },
  },
});
 */

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({});

/* export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.purple.darken1,
        secondary: colors.pink.darken3,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.black
      },
      dark: {
        primary: colors.purple.darken2,
        secondary: colors.pink.darken3,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
    },
  },
}) */