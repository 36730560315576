<template>
  <v-app-bar id="app-bar" absolute app flat height="62">
    <v-btn class="mx-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down font-weight-light">
      {{
        $route.name == "vouchers"
          ? $route.params.type == "1"
            ? $t("vouchers.receiptVouchers")
            : $t("vouchers.paymentVouchers")
          : $t($route.name)
      }}
    </v-toolbar-title>
    <v-spacer />
    <div class="mx-2" />
    <dashboard-core-settings />

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-1"
          min-width="0"
          color="grey lighten-1"
          text
          v-bind="attrs"
          v-on="on"
        >
          {{ $t("hello") + "  " + $store.getters.userName }}
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div>
          <v-list-item>
            <v-list-item-title>
              <v-btn text to="/auth">
                {{ $t("logout") }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import DashboardCoreSettings from "../components/Settings";
// Utilities
import { mapState, mapMutations } from "vuex";
export default {
  name: "DashboardCoreAppBar",

  components: {
    DashboardCoreSettings,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rtl: localStorage.rtl === "true",
  }),

  watch: {},
  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
  },
};
</script>
