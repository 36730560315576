import Vue from "vue";
import VueI18n from "vue-i18n";
import ar from "vuetify/lib/locale/ar";

Vue.use(VueI18n);

const messages = {
  ar: {
    ...require("@/locales/ar.json"),
    $vuetify: ar
  }
};

export default new VueI18n({
  locale:  "ar",
  fallbackLocale: "ar",
  messages
});
