<template>
  <router-view :key="$route.path" />
</template>

<script>

export default {
  name: "App",
};
</script>

<style>
@font-face {
  font-family: "Frutiger";
  src: local("Frutiger"),
    url("./fonts/Bahij_TheSansArabic-SemiBold.ttf") format("truetype");
}
.v-application {
  font-family: "Frutiger" !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
::-webkit-scrollbar-track-piece {
  background: rgb(71, 71, 71);
}
::-webkit-scrollbar-corner {
  background: rgb(49, 49, 49);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bordered td {
    border-left: rgba(0, 0, 0, .12) 1px solid;
}

table thead .fixed {
    position: sticky;
    right: 0;
    z-index: 2;
    background-color: white;
}

table tbody .fixed {
    position: sticky;
    right: 0;
    z-index: 2;
    background-color: white;
}
</style>
