<template>
  <v-list nav>
    <template v-for="(item, i) in items.filter((f) => f.visible == true)">
      <v-list-group
        exact
        :key="i"
        :prepend-icon="item.icon"
        v-if="item.children"
        v-model="item.active"
      >
        <template v-slot:activator>
          <v-list-item-content class="mx-n4">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          active-class="primary"
          color="white"
          v-for="(subItem, ind) in item.children.filter(
            (f) => f.visible == true
          )"
          :key="ind"
          :to="subItem.to"
        >
          <v-list-item-icon></v-list-item-icon>
          <v-list-item-title class="mx-n4">
            <v-icon small class="ml-1">mdi-circle-outline</v-icon>
            {{ subItem.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-item
        v-else
        :to="item.to"
        :key="i"
        active-class="primary"
        color="white"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="mx-n4">
          {{ item.title }}
        </v-list-item-title>
        <v-list-item-icon v-if="alerts[item.name] && alerts[item.name] > 0">
          <v-badge
            inline
            tile
            color="error"
            :content="alerts[item.name]"
          ></v-badge>
        </v-list-item-icon>
      </v-list-item>
    </template>
  </v-list>
</template>
<script>
export default {
  data() {
    return {
      items: [],
    };
  },
  computed: {
    alerts() {
      return this.$store.state.alerts;
    }
  },
  created() {
    this.$store.dispatch('GetUserAlerts');
    this.setItems();
    setTimeout(() => {
      var childs = this.items.filter((item) => {
        return (
            item.children &&
            item.children.some((it) => {
              return it.to == this.$route.path;
            })
        );
      });
      if (childs && childs.length > 0) {
        childs[0].active = true;
      }
    }, 200);
  },
  methods: {
    setItems() {
      this.items.push(
          {
            name: 'schools',
            title: this.$t("schools.schools"),
            icon: "mdi-school-outline",
            active: false,
            to: "/schools",
            visible: this.$store.getters.isInRole(5),
          },
          {
            name: 'schoolRequests',
            title: this.$t("schoolRequest.index"),
            icon: "mdi-bank-plus",
            active: false,
            to: "/schoolRequests",
            visible: this.$store.getters.isInRole(105),
          },
          {
            name: 'schoolRenew',
            title: this.$t("schoolRenew.index"),
            icon: "mdi-cloud-refresh",
            active: false,
            to: "/schoolRenew",
            visible: this.$store.getters.isInRole(106),
          },
          {
            name: 'schoolsGroups',
            title: this.$t("schoolGroups.schoolGroups"),
            icon: "mdi-town-hall",
            active: false,
            to: "/schoolsGroups",
            visible: this.$store.getters.isInRole(18),
          },
          {
            name: 'schoolYears',
            title: this.$t("schools.schoolYears"),
            icon: "mdi-calendar-month",
            active: false,
            to: "/schoolYears",
            visible: this.$store.getters.isInRole(10),
          },
          {
            name: 'directorates',
            title: this.$t("directorates.directorates"),
            icon: "mdi-domain",
            active: false,
            to: "/directorates",
            visible: this.$store.getters.isInRole(14),
          },
          {
            name: 'agencies',
            title: this.$t("agencies.index"),
            icon: "mdi-face-agent",
            active: false,
            to: "/agencies",
            visible: this.$store.getters.isInRole(101),
          },
          {
            name: 'supervisors',
            title: this.$t("supervisors.supervisors"),
            icon: "mdi-account-supervisor",
            active: false,
            to: "/supervisors",
            visible: this.$store.getters.isInRole(28),
          },
          {
            name: 'servers',
            title: this.$t("servers.servers"),
            icon: "mdi-server",
            active: false,
            to: "/servers",
            visible: this.$store.getters.isInRole(101),
          },
          {
            name: 'advertisements',
            title: this.$t("advertisements.index"),
            icon: "mdi-advertisements",
            active: false,
            to: "/advertisements",
            visible: this.$store.getters.isInRole(101),
          },
          {
            name: 'statistics',
            title: this.$t("statistics.index"),
            icon: "mdi-chart-bar",
            active: false,
            to: "/statistics",
            visible: this.$store.getters.isInRole(107),
          },
          {
            name: 'videoLibrary',
            title: this.$t("videoLibrary.index"),
            icon: "mdi-filmstrip-box-multiple",
            active: false,
            to: "/videoLibrary",
            visible: this.$store.getters.isInRole(110),
          },
          {
            name: 'users',
            title: this.$t("users.users"),
            icon: "mdi-account-group-outline",
            active: false,
            to: "/users",
            visible: this.$store.getters.isInRole(1),
          },
          {
            name: 'groups',
            title: this.$t("groups.groups"),
            icon: "mdi-format-list-group",
            active: false,
            to: "/groups",
            visible: this.$store.getters.isInRole(24),
          },
      );
    },
  },
  beforeCreate() {
  },
};
</script>
