<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />
    <dashboard-core-view />
  </v-app>
</template>

<script>
import DashboardCoreAppBar from "../components/AppBar";
import DashboardCoreDrawer from "../components/Drawer";
import DashboardCoreView from "../components/View";
import { mapGetters } from "vuex";

export default {
  name: "Home",

  components: {
    DashboardCoreAppBar,
    DashboardCoreDrawer,
    DashboardCoreView,
  },

  data: () => ({
    expandOnHover: false,
  }),
  computed: {
    ...mapGetters({
      overlay: "isLoading",
    }),
  },
};
</script>
<style>
.mySuccess {
  position: fixed !important;
  z-index: 111;
  top: 83px;
  right: 36%;
  width: 25%;
}
</style>
