import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    component: () => import("@/views/Index"),
    children: [
      {
        name: "Login",
        path: "",
        component: () => import("@/views/Account/Login"),
      },
    ],
  },
  {
    path: "/",
    name: "Main",
    component: Home,
    beforeEnter: (to, from, next) => {
      // window.document.title = to.name ? i18n.t(to.name) : "UIMS";
      if (
          localStorage.getItem("token") &&
          localStorage.getItem("token") !== "null" &&
          localStorage.getItem("token") !== null &&
          localStorage.getItem("token") !== undefined &&
          localStorage.getItem("token") !== "undefined" &&
          localStorage.getItem("token").substr(0, 2) === "ey"
      ) {
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/Dashboard"),
      },
      {
        path: "/users",
        name: "users.users",
        component: () => import("../views/Account/Users.vue"),
      },
      {
        path: "/groups",
        name: "groups.groups",
        component: () => import("../views/Account/Groups.vue"),
      },
      {
        path: "/changePassword",
        name: "users.changePassword",
        component: () => import("../views/Account/ChangePassword.vue"),
      },
      {
        path: "/notauthorize",
        name: "notauthorize",
        component: () => import("../views/NotAuthorized.vue"),
      },
      {
        path: "/schoolsGroups",
        name: "schoolGroups.schoolGroups",
        component: () => import("../views/SchoolsGroup/Index.vue"),
      },
      {
        path: "/directorates",
        name: "directorates.directorates",
        component: () => import("../views/Directorates/Index.vue"),
      },
      {
        path: "/schools",
        name: "schools.schools",
        component: () => import("../views/Schools/Index.vue"),
      },
      {
        path: "/schoolYears",
        name: "schools.schoolYears",
        component: () => import("../views/SchoolYear/Index.vue"),
      },
      {
        path: "/supervisors",
        name: "supervisors.supervisors",
        component: () => import("../views/Supervisors/Index.vue"),
      },
      {
        path: "/servers",
        name: "servers.servers",
        component: () => import("../views/Servers/Index.vue"),
      },
      {
        path: "/schoolRequests",
        name: "schoolRequest.index",
        component: () => import("../views/SchoolRequest/Index.vue"),
      },
      {
        path: "/statistics",
        name: "statistics.index",
        component: () => import("../views/Statistics/Index.vue"),
      },
      {
        path: "/schoolRenew",
        name: "schoolRenew.index",
        component: () => import("../views/SchoolRenew/Index.vue"),
      },
      {
        path: "/advertisements",
        name: "advertisements.index",
        component: () => import("../views/Advertisements/Index.vue"),
      },
      {
        path: "/videoLibrary",
        name: "videoLibrary.index",
        component: () => import("../views/VideoLibrary/Index.vue"),
      },
      {
        path: "/agencies",
        name: "agencies.index",
        component: () => import("../views/Agencies/Index.vue"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/views/Home"),
    children: [
      {
        name: "404 Error",
        path: "",
        component: () => import("@/views/Error"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((toRoute) => {
  window.document.title = i18n.t(toRoute.name);
});

export default router;
